import { isString, pick } from "lodash";
import { parseURL, getQuery, withHttps } from "ufo";
import type { RouteLocation } from "vue-router";

export type ResolvedLink = {
  href: string;
  target?: string;
};

export type ResourceLink = {
  resource:
    | "products"
    | "services"
    | "service-profiles"
    | "events"
    | "recipes"
    | "shops"
    | "product-categories";
  handle?: string;
  domain?: string;
  query?: Record<string, any>;
};

export type Link = string | ResourceLink;

const isExternalLink = (url: string): boolean => {
  if (url) {
    const { protocol, host } = parseURL(url);
    if (!protocol && !host) return false; // relative path
    if (protocol == "orie:") return false;
    return host !== "orie.market";
  }
  return false;
};

export const getResourceLink = (link: ResourceLink): ResolvedLink =>
  match(link.domain || "marketplace", {
    marketplace: () => {
      const router = useRouter();

      const resourcePath = match(link.resource.toLowerCase(), {
        products: "products",
        product: "products",
        "service-profiles": "service-profiles",
        // services: "services",
        // service: "services",
        events: "events",
        event: "events",
        recipes: "recipes",
        recipe: "recipes",
        stores: "shops",
        store: "shops",
        shops: "shops",
        "product-categories": "cat-products",
        "product-category": "cat-products",
      });

      let route: RouteLocation;

      if (link.handle) {
        if (resourcePath === "service-profiles") {
          route = router.resolve({
            name: "services-provider",
            params: { provider: link.handle },
            query: link.query,
          });
        } else {
          route = router.resolve({
            name: resourcePath + "-handle",
            params: { handle: link.handle },
            query: link.query,
          });
        }
      } else {
        route = router.resolve({
          name: resourcePath,
          query: link.query,
        });
      }

      return pick(route, ["href"]);
    },
    dashboard() {
      //
    },
  });

export const resolveLink = (
  link: Link | undefined
): ResolvedLink | undefined => {
  if (link) {
    if (!isString(link)) {
      return getResourceLink(link);
    }

    const url = parseURL(link);

    if (url.protocol == "orie:") {
      const [resource, handle] = url.pathname
        .replace(/^\/|\/$/g, "")
        .split("/");

      return getResourceLink({
        domain: url.host,
        resource: resource as any,
        handle,
        query: getQuery(link) as Record<string, any>,
      });
    } else {
      const resolved: ResolvedLink = { href: link };
      if (isExternalLink(link)) {
        resolved.href = withHttps(resolved.href);
        resolved.target = "__blank";
      }
      return resolved;
    }
  }
};
